




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'SmartSports',
  components: {
    SmartSportsPhone: () => import('./phone.vue'),
    SmartSportsDesk: () => import('./desk.vue')
  },
  setup() {
    const zyType = computed(() =>
      AppModule.device === DeviceType.Desktop
        ? 'SmartSportsDesk'
        : 'SmartSportsPhone'
    )
    return { zyType }
  }
})
